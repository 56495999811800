import React from 'react';
import { useDispatch } from 'react-redux';
import './Footer.scss'

const linkList = [
  {
    title: 'Company',
    children: [
      {
        title: 'Terms of Use',
        url: '',
        icon: '',
        path: 'terms'
      },
      {
        title: 'Privacy Policy',
        url: '',
        icon: '',
        path: 'privacy'
      },
      {
        title: 'Trading Rules',
        url: 'https://www.mvgx.com/trading-rules/',
        icon: ''
      },
    ]
  },
  {
    title: 'Product',
    children: [
      {
        title: 'Carbon Connect Suite',
        url: '',
        icon: '',
        path: 'product'
      },{
        title: 'Secured Money Market Portfolio Linked Note ("MMN")',
        url: '',
        icon: '',
        path: 'securities'
      },{
        title: 'Carbon Credit Marketplace',
        url: 'https://vcm.mvgx.com',
        icon: '',
      }
    ]
  },
  {
    title: 'Media',
    children: [
      {
        title: 'Press Releases',
        url: '',
        icon: '',
        path: 'news'
      },
      {
        title: 'Thought Leadership',
        url: '',
        icon: '',
        path: 'knowledge'
      },
      {
        title: 'Videos',
        url: '',
        icon: '',
        path: 'videos'
      }
    ]
  },

]
const stateMedia = [
  {
    title: 'LinkedIn',
    url: 'https://www.linkedin.com/company/metaversegreen',
    icon: require('../image/linkedin.png')
  },
  {
    title: 'YouTube',
    url: 'https://www.youtube.com/channel/UCr-vljVx-aAdGR5lE100JSA/',
    icon: require('../image/youtube.png')
  },
 /* {
    title: 'Instagram',
    url: 'https://instagram.com/metaversegreen',
    icon: require('../image/instagram.png')
  },
  {
    title: 'Twitter',
    url: 'https://twitter.com/metaverse_green',
    icon: require('../image/twitter.png')
  },
  {
    title: 'Facebook',
    url: 'https://www.facebook.com/Metaversegreen',
    icon: require('../image/faceBook.png')
  },*/
]

function ListCell({data}) {
  const curStyle = {
    padding: '5px 0',
    textAlign: 'left'
  }
  const dispatch = useDispatch();
  const routeToUrl = (data) => {
    dispatch({type: 'INCREMENT', payload: data.path})
  }
  return (
    <div>
      <div className='title'>{data.title}</div>
      {data.children.map((item, index) => (
        <div key={index} style={curStyle}>
          {item.url ? <a className='link-a' target="_blank" href={item.url} rel="noreferrer">{item.title}</a> :
            <span onClick={() => routeToUrl(item)} className='link-a'>{item.title}</span>}
        </div>
      ))}
    </div>
  )
}

function ContactCell({data}) {
  const name = `contact-icon ${data.title === 'Facebook' ? 'face-book' : ''}`
  return (
    <div>
      <a target="_blank" href={data.url} rel="noreferrer">
        <img className={name} src={data.icon} alt="icon"/>
      </a>
    </div>
  )
}

function Footer() {
  const imgSrc = require('../image/mvgx-logo.png')
  return (
    <div className='footer common-wrapper'>
      <div className='flex-box'>
        <div className='logo-cell'>
          <img className='company-logo' src={imgSrc} alt="logo"/>
        </div>
        <div className='link-wrapper'>
          {linkList.map((item, index) => (
            <div key={index}>
              <ListCell data={item}/>
            </div>
          ))}
        </div>
      </div>
      <div className='line' />
      <div className='footer-bottom'>
        <div className='footer-left'>
          <div className='icon-wrapper'>
            {stateMedia.map((item, index) => (
              <div key={index}>
                <ContactCell data={item}/>
              </div>
            ))}
          </div>
        </div>
        <div className='footer-right'>
          <p>The MVGX platform is operated by MetaVerse Green Exchange Pte. Ltd. (“MVGX”). MVGX is regulated by the Monetary Authority of Singapore and holds a Capital Markets Services (CMS) license for the dealing of capital markets products and providing custodial services in accordance with the conditions therein; and a Recognised Market Operator (RMO) license for the operation of an approved recognized market in accordance with the conditions therein.</p>
          <p>Copyright © 2023 MetaVerse Green Exchange</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;