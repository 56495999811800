import React, { useEffect, useState} from 'react';
import './Header.scss'
import Logo from '../image/mvgx-logo.png'
import { Menu, Drawer, ConfigProvider } from 'antd';
import { BarsOutlined } from '@ant-design/icons';
import { useNavigate  } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';


const menuList = [
  {
    key: 'aboutUs',
    label: 'About Us',
    disabled: false,
    style: {
      marginRight: '20px'
    },
    children: [
      {
        key: 'mvgxTech',
        label: 'Tech',
        disabled: false,
        islink: '1',
      },
      {
        key: 'aboutExchange',
        label: 'Exchange',
        disabled: false,
        islink: '1',
      },
      {
        key: 'aboutSecurities',
        label: 'Securities',
        disabled: false,
        islink: '0',
        color: '1'
      },
      {
        key: 'asiaGreenFund',
        label: 'Funds',
        disabled: false,
        islink: '1',
      },
      {
        key: 'team',
        label: 'Our Team',
        disabled: false,
        islink: '0',
        color: '1'
      },
    ]
  },
  {
    key: 'media',
    label: 'Media',
    disabled: false,
    style: {
      marginRight: '20px'
    },
    children: [
      {
        key: 'pressRelease',
        label: 'Press Release',
        disabled: false,
        islink: '0',
        color: '1'
      },
      {
        key: 'videos',
        label: 'Videos',
        disabled: false,
        islink: '0',
        color: '1'
      },
    ]
  },
]
let curName = sessionStorage.getItem('path') || ''
let selectedKeys = [sessionStorage.getItem('path') || ''];
let menuColor = sessionStorage.getItem('menuColor') || ''
function Header() {
  const navigate = useNavigate();
  const [headerColor, setHeaderColor] = useState('');

  const count = useSelector((state) => state.counter.count);
  const dispatch = useDispatch();
  useEffect(() => {
    // 在组件挂载后，添加 Redux 数据变化的监听
    if (count) {
      let curData = ''
      if (count === 'tech') {
        curData = {item: {props: menuList[0].children[0]}, key: 'mvgxTech'}
      }
      if (count === 'exchange') {
        curData = {item: {props: menuList[0].children[1]}, key: 'aboutExchange'}
      }
      if (count === 'finanal') {
        curData = {item: {props: menuList[0].children[4]}, key: 'finanal'}
      }
      if (count === 'securities') {
        curData = {item: {props: menuList[0].children[2]}, key: 'mmn'}
      }
      if (count === 'news') {
        curData = {item: {props: menuList[1].children[0]}, key: 'pressRelease'}
      }
      if (count === 'videos') {
        curData = {item: {props: menuList[1].children[1]}, key: 'videos'}
      }
      if (count === 'product') {
        curData = {item: {props: menuList[0].children[1]}, key: 'CarbonConnectSuite'}
      }
      if (count === 'terms') {
        curData = {item: {props: menuList[0].children[2]}, key: 'terms'}
      }
      if (count === 'privacy') {
        curData = {item: {props: menuList[0].children[2]}, key: 'privacy'}
      }
      if (count === 'knowledge') {
        curData = {item: {props: menuList[0].children[0]}, key: 'knowledge'}
      }
      /* eslint-disable */
      menuClick(curData)
    }
    if (menuColor) {
      setHeaderColor('hasColor')
    }
    const handleScroll = () => {
      menuColor = sessionStorage.getItem('menuColor') || ''
      const distance = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (!menuColor) {
        if (distance > 150) {
          setHeaderColor('hasColor')
        } else {
          setHeaderColor('')
        }
      } else {
        setHeaderColor('hasColor')
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [count]);

  const menuClick = ({item, key}) => {
    // 重置count
    dispatch({type: 'INCREMENT', payload: ''})
    setOpen(false);
    window.scrollTo(0, 0);

    if (item.props.islink === '0') {
      curName = key;
      selectedKeys = [curName]
      setHeaderColor('')
      sessionStorage.removeItem('menuColor')
      sessionStorage.setItem('path', curName)
    } else {
      sessionStorage.removeItem('menuColor')
    }

    if (item.props.color) {
      setHeaderColor('hasColor')
      sessionStorage.setItem('menuColor', 'true')
    }
    if (key === "CarbonConnectSuite") {
      navigate('/ProductsAndServices')
    }
    if (key === "mmn") {
      navigate('/mmn')
    }
    if (key === "terms") {
      navigate('/terms')
    }
    if (key === "privacy") {
      navigate('/privacyPolicy')
    }
    if (key === "knowledge") {
      navigate('/knowledge')
    }

    if (key === "mvgxTech") {
      window.open('https://www.mvgxtech.com/', "_blank")
      // dispatch({type: 'INCREMENT', payload: 'tech'})
      // navigate('/about-us')
    }
    if (key === "aboutExchange") {
      window.open('https://trade.mvgx.com/', "_blank")
      // dispatch({type: 'INCREMENT', payload: 'exchange'})
      // navigate('/about-us')
    }
    if (key === 'aboutSecurities') {
      // dispatch({type: 'INCREMENT', payload: 'securities'})
      // navigate('/about-us')
      navigate('/mmn')
    }

    if (key === "finanal") {
      navigate('/coming')
    }
    if (key === "overviewMvgx") {
      navigate('/about-mvgx')
    }
    if (key === "team") {
      navigate('/our-team')
    }
    if (key === "mvgxExchange") {
      window.open('https://trade.mvgx.com/login', "_blank")
    }
    if (key === "careers") {
      navigate('/careers')
    }
    if (key === "chinaCarbonReports") {
      navigate('/CNCarbon-report')
    }
    if (key === "pressRelease") {
      navigate('/press-release')
    }
    if (key === "videos") {
      navigate('/videos')
    }
    if (key === "contactUs") {
      const email = 'contact@mvgx.com';
      window.location.href = `mailto:${email}`;
      // window.open('https://www.mvgx.com/contact-us/', "_blank")
    }
    if (key === "asiaGreenFund") {
      window.open('http://www.asiagreenfund.com/html/en_index/', "_blank")
    }
    if (key === "carbonManagement") {
      navigate('/tech-services', {state: {id: '1'}})
    }
    if (key === "carbonRating") {
      navigate('/tech-services', {state: {id: '2'}})
    }
    if (key === "carbonCreditDevelopment") {
      navigate('/tech-services', {state: {id: '3'}})
    }
    if (key === "carbonCreditRegistry") {
      navigate('/tech-services', {state: {id: '4'}})
    }
    if (key === "carbonCreditTrading") {
      navigate('/tech-services', {state: {id: '5'}})
    }
  }
  const backHome = () => {
    window.scrollTo(0, 0);
    selectedKeys = ['']
    setHeaderColor('')
    sessionStorage.removeItem('menuColor')
    sessionStorage.removeItem('path')
    navigate('/')
  }
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(!open);
  };
  const onClose = () => {
    setOpen(false);
  };

  let classNames = `header ${headerColor}`
  return (
    <div className={classNames}>
      <div className='flex-box'>
        <img className='logo' onClick={backHome} src={Logo} alt="mvgx"/>


        <ConfigProvider
          theme={{
            components: {
              Menu: {
                dropdownWidth: 'auto',
              }
            },
          }}
        >
          <Menu
            selectedKeys={selectedKeys}
            className='horizontal-mvgx'
            onClick={menuClick}
            style={{
              width: 290,
              background:"transparent",
              color: '#fff',
              fontSize: '14px',
              fontWeight: '500',
              border: 'none',
            }}
            mode="horizontal"
            items={menuList}
          />
        </ConfigProvider>

        <BarsOutlined
          className='inline-mvgx'
          onClick={showDrawer}
          rotate={open ? -90 : 0}
          style={{
            fontSize: '35px',
            color: '#fff'
          }}  />

        <a onClick={() => window.location.href = `mailto:contact@mvgx.com`} target='_blank' rel="noreferrer" className='contact-wrapper'>
          <img className='contact-img' src={require('../image/Vector.png')} alt=""/>
          Contact Us
        </a>

        <Drawer
          width='360px'
          placement="right"
          onClose={onClose} open={open}
        >
          <Menu
            getContainer={true}
            onClick={menuClick}
            style={{
              background:"transparent",
              fontSize: '14px',
              fontWeight: '500',
              border: 'none',
            }}
            mode="inline"
            items={menuList}
          />
        </Drawer>
      </div>
    </div>
  );
}

export default Header;